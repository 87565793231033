import * as React from 'react'

const Panel = ({title, extra, children}) => {
    return (
        <div className="flex flex-col items-center justify-center space-y-3 p-8 bg-white rounded-xl shadow-lg text-center">
            <span className="block">{title}</span>
            <span className="text-persian-green-500 text-xl block">{children}</span>
            <span className="text-gray-400 text-sm block">{extra}</span>
        </div>
    )
}

export default Panel