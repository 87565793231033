import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Panel from '../components/panel'
import { ChevronRightIcon } from '@heroicons/react/outline'
import SEO from '../components/seo'

const Contatti = () => {
    return (
        <Layout>
            <SEO title="Contatti" description="Contattaci Siamo sempre a disposizione per rispondere alle tue domande." />
            <section className="px-6 py-12 lg:py-32 bg-gray-50">
                <div className="container mx-auto">
                    <div className="mb-12 text-center">
                        <h1 className="text-5xl text-persian-green-500 mb-3">Contattaci</h1>
                        <span className="text-gray-400 text-lg">Siamo sempre a disposizione per rispondere alle tue domande, Thelgo può aiutare la tua azienda a migliorare le produttività.</span>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <Panel title="Email">
                            info@cascinanet.it
                        </Panel>

                        <Panel title="Call" extra="Lunedì - Venerdì, 9:30-12:30 15:30-18:30">
                            +39 050 0987330
                        </Panel>

                        <Panel title="Dove">
                            Via G. Savonarola, 32 Pontedera - PISA
                        </Panel>

                        <Panel title="DEMO">
                            Prova il nostro software
                            <Link to="/demo" className="inline-flex mt-3 text-sm text-gray-400 font-bold hover:text-persian-green-500 transition-colors">Richiedi una demo <ChevronRightIcon className="h-4 w-4" /></Link>
                        </Panel>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contatti